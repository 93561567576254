<template>
  <div>
    <Tabs  v-model="tabcon" value="1">
        <Tab-pane label="订单信息" name="1"></Tab-pane>
        <Tab-pane label="待审核" name="2"></Tab-pane>
    </Tabs>

    <div v-if="tabcon=='1'">
        <div class="search-list">
            <div class="list-al">订单编号：<Input placeholder="请输入订单编号" v-model="orderSearch.order_no"/></div>
            <div class="list-al">应用名：<Input placeholder="请输入应用名" width="350px" v-model="orderSearch.title"/> </div>
            <div class="list-al">平台名、手机号：<Input placeholder="请输入平台名、手机号" v-model="orderSearch.store_name"/></div>
            <div class="list-al">订单状态：
                <Select v-model="orderSearch.status" @on-change="chooseType" style="width: 200px"  placeholder="请选择订单状态"  >
                    <Option v-for="item in typeList"  :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="list-al">订单日期：
                <Date-picker :value="value2" format="yyyy-MM-dd" type="daterange" @on-change='chosetime' placement="bottom-end" placeholder="选择日期" style="width: 240px" ></Date-picker>
            </div>
            <div class="list-al">
                <Button type="primary" icon="ios-search-outline" @click="searchListA">搜索</Button>
            </div>
        </div>   
        <Row :gutter="16" style="margin-bottom: 24px;margin-top: 15px">
            <Col span="6">
                <div>
                    <Button icon="md-add" type="primary"  @click="addNotice('add')">新增订单</Button>
                </div>
            </Col>
        </Row>
        <Table border :columns="platformColumns" :data="tableData" >     
            <template slot-scope="{ row }" slot="operation">        
                <Button v-if="row.status == 1"  size="small" style="margin-right: 5px" @click="goRefund(row)">退款</Button>
                <Button v-if="row.status == 0" type="success" size="small" style="margin-right: 5px" @click="paySuccessful(row)" >支付</Button>
                <Button v-if="row.status == 0" type="primary" size="small" style="margin-right: 5px" @click="showedit(row)" >编辑</Button>        
                <Button v-if="row.status == 0" type="error" size="small" @click="deldata(row.id)" >删除</Button>
            </template>
            <template slot-scope="{ row }" slot="type">   
                <div>{{row.type==0?'saas':'电子合同'}}</div>     
            </template>
            
            <template slot-scope="{row} " slot="status">
                <div>
                    <div v-if="row.status == 1 " style="color: #1CBF6D">已完成</div>
                    <div v-else-if="row.status == 0" style="color: #ED4014">未支付</div>
                    <div v-else style="color: #2D8CF0">已退款</div>
                    <!-- <Button v-else type="warning" size="small">未付款</Button> -->
                </div>
            </template>
            <template slot-scope="{row} " slot="applicationarr_id">
                <div >
                    {{row.applicationarr_id.name}}
                </div>
            </template>
            <template slot-scope="{row} " slot="storearr_id_phone">
                <div >
                    {{row.storearr_id.phone}}
                </div>
            </template>
            <template slot-scope="{row} " slot="un_name">
                <div >
                    {{row.storearr_id.un_name}}
                </div>
            </template>
        </Table>
      <br/>
      <Page :total="total" :current="orderSearch.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="orderSearch.limit"/>
    </div>
    
    <div v-if=" tabcon =='2'">
        <div class="search-list">
            <div class="list-al">状态：
                <Select v-model="examineSearch.status" @on-change="get_signtemplatelist" style="width: 200px"  placeholder="请选择订单状态"  >
                    <Option v-for="item in orderType"  :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="list-al">类别：
                <Select v-model="examineSearch.type" @on-change="get_signtemplatelist" style="width: 200px"  placeholder="请选择类别"  clearable>
                    <Option v-for="item in orderinfo"  :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="list-al">
                <Button type="primary" icon="ios-search-outline" @click="searchListB">搜索</Button>
            </div>
        </div>   
        <Table border :columns="examineColumns" :data="examinetableData" style="margin-top:15px">                 
            <template slot-scope="{ row }" slot="type">        
                <div v-if='row.type == 1'>短信签名</div>
                <div v-if='row.type == 2'>短信模板</div>                
                <div v-if='row.type == 3'>独立域名</div>
            </template>
            <template slot-scope="{ row }" slot="operation">        
                <Button v-if="row.status == 1" type="success" size="small" style="margin-right: 5px"  @click="examineSuccessful(row,3)" >通过</Button>
                <Button v-if="row.status == 1" type="error" size="small" @click="examineSuccessful(row,2)" >驳回</Button>
            </template>
            <template slot-scope="{row} " slot="status">
                <div >
                    <!-- 示例：0 状态 0.代付款 1 审核中 已付款 2 审核失败 3.审核通过 4.使用中 -->
                    <div v-if="row.status == 0 " style="color: #4877E8">待付款</div>
                    <div v-if="row.status == 1 " style="color: #545F78">已付款 待审核</div>
                    <div v-if="row.status == 2 " style="color: #FD5454">审核失败</div>
                    <div v-if="row.status == 3 " style="color: #2A7E1C">审核通过</div>
                    <div v-if="row.status == 4 " style="color: #FFB519">使用中</div>                    
                </div>
            </template>
            <template slot-scope="{row} " slot="applicationarr_id">
                <div >
                    {{row.applicationarr_id.name}}
                </div>
            </template>
            <template slot-scope="{row} " slot="storearr_id_phone">
                <div >
                    {{row.storearr_id.phone}}
                </div>
            </template>
            <template slot-scope="{row} " slot="un_name">
                <div >
                    {{row.storearr_id.un_name}}
                </div>
            </template>
        </Table>
      <br/>
      <Page :total="extotal" :current="examineSearch.page" style="text-align:right" show-elevator show-total @on-change="pageExChange" :page-size="examineSearch.limit"/>
    </div>

    <Modal
        :title="formTitle==='add'?'新增订单信息':'编辑订单信息'"
        v-model="showModel"
        width="600"
        :mask-closable="false"
        :footer-hide="true"
        @on-cancel="cancel"
        class-name="vertical-center-modal">
        <Form :model="formData" ref="formData" :rules="ruleValidate" :label-width="120" style="width: 500px">            
            <FormItem label="选择应用" >
                <Select v-model="formData.application_id"  :disabled="formTitle === 'edit'" placeholder="请先选择应用名称" filterable @on-change="chooseAPP">
                    <Option v-for="item in appList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="选择用户"   v-if="formTitle=='add'">
                <!-- <Input v-model="formData.payUser" :disabled="formTitle === 'edit'" placeholder="请输入购买账户"></Input> -->
                <Select v-model="formData.store_id" :disabled="formTitle === 'edit'||formData.application_id==''" placeholder="请选择用户名称" filterable>
                    <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.un_name }} {{item.phone}}</Option>
                </Select>
            </FormItem>
            <FormItem label="应用价格" v-if="formTitle=='add'">
                <Input  v-if="price_group.length == 1"  v-model="laljkag" disabled placeholder="请输入应用价格"></Input>
                <Input  v-else-if="price_group.length == 0"   disabled placeholder="请选择应用价格"></Input>
                <!-- @on-change="choosePrice" :label-in-value="true" -->
                <Select v-else v-model="selectapp" :disabled="formTitle === 'edit'" @on-change="choosePrice"  placeholder="请选择应用价格" filterable >
                    <!-- <span v-if='item.number!=null'>/{{item.number}}条</span> -->   
                    <Option v-for="(item,index) in price_group" :value="index" :key="index">{{ item.pnm }} </Option>
                </Select>
            </FormItem>                 
            <FormItem label="支付价格" prop="price">
                <Input v-model="formData.price" type="number" placeholder="请输入支付价格"></Input>
            </FormItem>
            <FormItem :label="morqtitle+'内容'"  prop="title" v-if='showInput'>
                <Input v-model="formData.title"  :placeholder="'请输入短信'+morqtitle+'内容'"></Input>
            </FormItem>
            <FormItem label="独立域名"  prop="domain_name" v-if='showDomain'>
                <Input v-model="formData.domain_name"  placeholder="请输入独立域名"></Input>
            </FormItem>
            <FormItem label="订单备注" >
                <Input v-model="formData.remarks" type="text" placeholder="请输入订单备注"></Input>
            </FormItem>

            
            <FormItem>
                <Button :loading="loadingb" type="primary" @click="handleSubmit('formData')">确认</Button>
                <Button @click="restprice()" style="margin-left: 8px" v-if="formTitle=='add'">重置</Button>
            </FormItem>
        </Form>
    </Modal>
    <!-- showReason -->
    <Modal v-model="showReason" :mask-closable="false" footer-hide width="450px" title="请输入驳回原因" >
        <div class="mod-pay-cont">
            <Input v-model="failure_reason" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="请输入驳回原因"></Input>
        </div>
        <div class="footer-reason">
            <Button type="primary" :loading='loadinga' @click="submitReason">确认</Button>
            <Button @click="closeReason()" style="margin-left: 8px">取消</Button>
        </div>        
    </Modal>
    <Modal v-model="showRefundModal" :mask-closable="false" footer-hide width="450px" title="请问您是否确认退款该订单！！！" >
        <!-- <div class="mod-pay-cont refend-con" >
           <div class = "refund-tit" style="display:flex;justify-content: flex-end;"><div style="color:red">*</div>退款金额</div>
           <Input v-model="refundForm.price" type="number"  placeholder="请输入退款金额"></Input>
        </div> -->
        <div class="mod-pay-cont refend-con" style="margin-bottom: 48px;">
           <div class = "refund-tit">备注</div>
           <Input v-model="refundForm.remarks" type="text"  placeholder="请输入备注"></Input>
        </div>
        <div class="footer-reason">
            <Button type="primary" :loading='loadinga' @click="submitRefund()">确认</Button>
            <Button @click="closeRefund()" style="margin-left: 8px">取消</Button>
        </div>        
    </Modal>
    <Modal v-model="showPay" :mask-closable="false" footer-hide width="450px" title="支付方式" >
        <div class="mod-pay-cont">
            <div class="pay-pay">
                <div class="pay-items"> <img @click="paychoose" src="@/assets/tangimg/xianxia.png" />
                    <div style="margin:10px 5px"  ><Radio @on-change="paychooseA" v-model="xianxia">线下支付</Radio></div> 
                </div>            
                <div class="pay-items"> <img @click="payonint"  src="@/assets/tangimg/xianshang.png" />
                    <div style="margin:10px 5px" ><Radio  @on-change="payonintA" v-model="xianshang">线上支付</Radio></div> 
                </div>              
            </div>
        </div>
    </Modal> 
    <Modal v-model="payMa" :mask-closable="false" footer-hide width="600px" title="收款二维码" @on-cancel="cancelpay">        
        <div class="which">支付方式：
        <Radio-group v-model="animal" @on-change="whichOne">
            <Radio label="微信支付"></Radio>
            <Radio disabled label="支付宝支付"></Radio>
        </Radio-group>
        </div>
        <div class="mod-pay-cont" v-if="animal">
            <div class="pay-num">￥{{payData.price}}</div>
            <div class="pay-ma">
                <Spin fix v-if="payData.qrcode_url==''">
                    <img class="demo-spin-icon-load" style="width:50px;height:50px"  src="@/assets/tangimg/20220720113958.gif" />
                    <div>Loading...</div>
                </Spin>
              <img v-if="payData.qrcode_url !=''" style="width: 200px;height: 200px;border: 1px solid #4877E8;margin: 0px 0px;" :src="payData.qrcode_url" />
            </div>
            <!-- <div v-if="payData.qrcode_url==''">请稍等.....</div> -->
            <div >扫码支付</div>
        </div>
    </Modal>
    <Modal v-model="paySucces" :mask-closable="false" footer-hide width="600px" >      
        <div class="mod-pay-succ">
            <div><img src="@/assets/tangimg/zhifuwancheng.png" /></div>
            <span class="titlesuc">恭喜你支付完成!</span>
            <div class="over-list" style="margin-top:40px">商品订单：<span>{{payFormData.order_no}}</span></div>
            <div class="over-list" style="">商品名称：<span>{{zhongName}}<span v-if="payFormData.number != 0"> —— {{payFormData.number}}条</span></span></div>      
            <div class="over-list" style="margin-bottom:40px">支付金额：<span>{{payFormData.price}}元</span></div>    
        </div>        
    </Modal>
    
  </div>
</template>
<script>
// import vm from '@/utils/util.js'
import { get_storelist,apply_refund,get_order_list,create_order,get_applicationlist,update_order,del_order,pay_order,get_signtemplatelist,examine_order,get_orderstatus }from "@/api/appcenter"
export default {
  data() {
    return {      
        typeList:[
            {value:3,label:'全部类型'},  
            {value:0,label:'待支付'},
            {value:1,label:'付款成功'},
            {value:2,label:'已退款'},                       
        ], 
        orderType: [
            {value:5,label: '全部状态'},
            {value:0,label:'待付款'},
            {value:1,label:'已付款 待审核'},
            {value:2,label:'审核失败'},
            {value:3,label:'审核通过'},
            {value:4,label:'使用中'},
            
        ], 
        orderinfo:[
            // {value:0,label:'短信签名'},
            {value:1,label:'短信签名'},
            {value:2,label:'短信模板'},
            {value:3,label:'独立域名'},
        ],
        inSearch: {
            status:0
        },
        loadinga: false,
        loadingb: false,
        paySucces:false,
        xianshang:false,
        xianxia:false,
        payMa: false,
        showPay: false,
        appList: [],
        price_group:[],
        userList: [],
        allUserList: [],
        tabcon: '1',      
        selectnumber: 0, 
        animal:"",
        selectapp: "",
        failure_reason: "",
        refundId : '',
        showRefundModal:false,
        editOrder_no: "",
        laljkag: "",      
        timer: "", 
        payData: {
            qrcode_url : '',
            price : "",
        },      
        formData:{
            store_id: "",
            application_id: "",
            id: "",
            price: '',
            title: '',
            remarks: '',
            domain_name: '',//独立域名
        },
        value2: ['',''],
        payFormData:{
            price: "",
            order_no: '',
            number: '' 
        },      
        examineColumns: [
            {
                title: '序号',
                key: 'id',
                align:'center'
            },
            {
                title: '审核内容',
                key: 'title',
                align:'center',
            },
            {
                title: '类别',
                key: 'type',
                align:'center',
                slot:"type"
            },  
            {
                title: '状态',
                key: 'status',
                align:'center',
                slot:"status"
            }, 
            {
                title: ' 失败原因',
                key: 'failure_reason',
                align:'center',
            },
            {
                title: '创建时间',
                key: 'created_at',
                align:'center'
            }, 
            {
                title: '操作',
                slot: 'operation',
                align:'center',
                width:250
            }
        ],
        platformColumns: [
            {
                title: '订单编号',
                key: 'order_no',
                align:'center',
                width: 250
            },
            {
                title: '应用名',
                key: 'applicationarr_id',
                align: 'center',
                slot: "applicationarr_id"
            },
            {
                title: '平台名',
                key: 'un_name',
                align:'center',
                slot: "un_name"
            },   
            {
                title: ' 账户',
                key: 'storearr_id_phone',
                align: 'center',
                slot: "storearr_id_phone"
            },
            {
                title: '价格',
                key: 'price',
                align: 'center'
            }, 
            {
                title: '订单状态',
                key: 'status',
                align: 'center',
                slot: "status"
            },
            {
                title: '订单平台',
                key: 'type',
                align: 'center',
                slot: "type"
            },
            {
                title: '订单时间',
                key: 'created_at',
                align: 'center',
                width: 200
            },
            {
                title: '订单备注',
                key: 'remarks',
                align: 'center',
            },
            {
                title: '支付时间',
                key: 'payment_time',
                align:'center'
            },
            {
                title: '操作',
                slot: 'operation',
                align: 'center',
                width: 250
            }
        ],
        tableData:[],
        examinetableData:[],
        data6: [],
        showModel: false,
        showReason: false,
        showInput: false,
        showDomain: false,//独立域名显示
        morqtitle:'',
        formItem: {
            title: '',
            synopsis: '',
            notice_type: '',
            store_id: '',
            id: '',
            user_type:''
        },  
        //退款form
        refundForm: {
            id:'',
            price:'',
            remarks:'',
            oldPrice:''
        }, 
        zhongzhuanrow: {},
        ruleValidate: {        
            price: [
                { required: true, message: '请输入支付价格', trigger: 'blur' }
            ],        
        },
        formTitle: 'add',
        detailTable: false,
        detailTa: [
            {
            title: '名称',
            key: 'un_name'
            },
            {
            title: '账户',
            key: 'account_name'
            },
            {
            title: '类型',
            key: 'type'
            },
            {
            title: '时间',
            key: 'created_at'
            },
        ],
        detailNu: [],
        orderSearch: {
            limit: 10,
            page: 1,
            title: '',
            status: 3,//1支付成功   0待支付  2已退款
            start_time: "",        
            end_time: "",
            store_name: "",
            order_no: "",
        },
        examineSearch: {
            page: 1,
            limit: 10,
            status: 5,
            type: ""
        },
        zhongId: '',
        zhongName: '',
        zhongPice: '',
        noticeDetail: {},
        unList: [],
        total: 0,
        extotal: 0,
        identity: 1,
    }
  },
  created() {
    this.getList()
    this.get_signtemplatelist()
    this.get_storelist()
    this.get_applicationlist()
  },
  mounted(){     
    // var that = this
    //     vm.$on('demo', function (msg) {
    //         console.log('msg',msg)
    //         that.payOK(msg)
    //     })
  },
  destroyed () {
  },
  methods: {    
    chooseType(e){
        // this.orderSearch.type = e
        console.log(e)
    },
    pollingOrder(e){
        console.log(e)
        this.timer = setInterval(() => {
            this.$store.commit('changePollingSpin', false)
            get_orderstatus({order_no:e}).then(res=>{
                if(res.data.status == 1){
                    this.payOK(res)
                    clearInterval(this.timer)
                }
                this.$store.commit('changePollingSpin', true)
            }).catch(res=>{
                this.$store.commit('changePollingSpin', true)
                this.$Message.error(res.msg)
                clearInterval(this.timer)
            })
        }, 2000)
    },
    payOK(a){
        if(a.code==200){
            if(this.payMa){
                this.paySucces = true
                this.payFormData.price = a.data.price
                this.payFormData.order_no = a.data.order_no
                this.payFormData.number = a.data.number 
                this.showPay = false
                this.payMa = false
            } 
            this.get_signtemplatelist()
            this.getList()
        }else{
            console.log('出错了')
        }
    },
    get_applicationlist(){
        get_applicationlist().then(res => {
            this.appList = res.data
        }).catch(res => {
            this.$Message.error(res.msg)
        })
    },
    paychooseA(e){
        if(e){
            this.xianxia = false
            this.paychoose()
        }
    },
    payonintA(e){
        if(e){
            this.xianshang = false
            this.payonint()
        }
    },
    whichOne(){
        this.payData.qrcode_url = ''
        pay_order({id:this.zhongId,pay_type:this.animal=='微信支付'? 1 : 2}).then(res=>{
            // this.getList()
            // this.$Message.success(res.msg)
            // this.showPay = false
            this.payData.qrcode_url = res.data.qrcode_url
            this.pollingOrder(res.data.out_trade_no)
        }).catch(res=>{
            this.payMa = false
            this.showPay = false
            this.$Message.error(res.msg)
            // console.log(res)
        })
    },
    get_storelist(){
        get_storelist().then(res => {
            this.userList = res.data
            this.allUserList = res.data
        }).catch(res => {
            this.$Message.error(res.msg)
        })
    },
    searchListA(){
        this.orderSearch.page = 1
        this.getList()
    },
    searchListB(){
        this.examineSearch.page = 1
        this.get_signtemplatelist()
    },
    get_signtemplatelist(){
        get_signtemplatelist(this.examineSearch).then(res => {
            this.examinetableData = res.data.data
            this.extotal = res.data.total  
            this.limit = res.data.limit  
        }).catch(res => {
            this.$Message.error(res.msg)
        })
    },
    getList() {
        get_order_list(this.orderSearch).then(res => {
            this.tableData = res.data.data
            this.total = res.data.total  
            this.limit = res.data.limit      
        }).catch(res => {
            this.$Message.error(res.msg)
        })
    },
    pageChange(index) {
        this.orderSearch.page = index
        this.getList()
    },
    pageExChange(index) {
        this.examineSearch.page = index
        this.get_signtemplatelist()
    },
    chosetime(e){
        this.value2[0] = e[0]
        this.value2[1] = e[1]
    },
    searchList(){      
        this.orderSearch.start_time = this.value2[0]
        this.orderSearch.end_time = this.value2[1]
        this.getList()
    },
    addNotice(type) {
        if (type === 'add') {
            this.formTitle = 'add'
            this.showInput = false
            this.showDomain = false
        }
        this.showModel = true
    },
    choosePrice(e){
        if(e == undefined){
            this.selectnumber = 0
            this.selectapp = ''  
            this.formData.price = ''
        }else{
            this.selectnumber = this.price_group[e].number == 0 ? 0 :this.price_group[e].number
            this.formData.price = this.price_group[e].price
        }        
        // let arr = []
        // if(e.label!=undefined){
        //     arr = e.label.split("元")
        //     this.formData.price = arr[0]
        // }
    },
    cancelpay(){
        clearInterval(this.timer)
    },
    chooseAPP(e){
        // console.log('e',e)
        this.price_group = []
        this.formData.price = ''
        this.selectapp = ''
        this.selectnumber = 0
      // this.selectapp = ''
        this.appList.forEach( (item) => {
            if(item.id == e){
                if(item.permissions_id == 998||item.permissions_id == 999){
                    this.formData.title = ''
                    this.showInput = true
                    this.morqtitle = item.permissions_id == 998 ? '模板':'签名'
                }else {
                    this.formData.title = ''
                    this.showInput = false
                }
                //独立域名
                if(item.permissions_id == 997){
                    this.formData.domain_name  = ''
                    this.showDomain = true
                }else {
                    this.formData.domain_name = ''
                    this.showDomain = false
                }
                //判断是否无小程序授权副管理员
                if(item.permissions_id == 996){
                    this.identity = 3
                    this.userList = []
                    this.allUserList.forEach(item=>{
                        if(item.identity == 3){
                            this.userList.push(item)
                        }
                    })
                    this.formData.store_id = ''
                }else{
                    this.identity = 1
                    this.userList = []
                    this.allUserList.forEach(item=>{
                        if(item.identity == 1){
                            this.userList.push(item)
                        }
                    })                    
                    this.formData.store_id = ''
                }
                item.price_group.forEach((item2)=>{
                    this.price_group.push(item2)
                })
                if(this.price_group.length == 1){
                    if(item.permissions_id == 1000){
                        this.laljkag = this.price_group[0].price+'元/'+ this.price_group[0].number + '条'
                        //   this.selectapp = this.price_group[0].number
                        this.selectnumber = this.price_group[0].number
                        this.formData.price = this.price_group[0].price
                    }else{
                        this.laljkag = this.price_group[0].price+'元'
                        this.formData.price = this.price_group[0].price
                        this.selectnumber = 0
                    }
                }
                if(this.price_group.length > 1){
                    this.price_group.forEach(item3=>{
                        if(item.permissions_id == 1000){
                            item3.pnm = ''
                            item3.pnm  = item3.price + '元/' +item3.number +'条'
                        }else{
                            item3.pnm = ''
                            item3.pnm  = item3.price + '元' 
                        }
                    })
                }
            }
        })
    },
    paychoose(){
        if(this.xianxia){
            this.xianxia = false
        }else{
            this.xianshang = false
            this.xianxia =  true
            this.offPayment(1)
        }
    },
    restprice(){
        this.formData.price = ''
        this.formData.store_id = ''
        this.formData.application_id = ''
        this.selectnumber = 0
        this.selectapp = ''
        this.formData.remarks = ''
        this.formData.domain_name = ''
    },
    payonint(){
        if(this.xianshang){
            this.xianshang = false
        }else{
            this.xianshang = true 
            this.xianxia = false 
            this.payMa = true
            this.payData.qrcode_url = []
            this.animal = "微信支付"
            this.whichOne()
        }
    },
    handleSubmit (name) {
        if(this.formData.price == 0){
            if(this.formData.remarks == ''){
                return this.$Message.error('0元购买,请填写订单备注')
            }            
        } 
        if(this.formData.store_id == '') return this.$Message.error('请选择用户')
        if(this.formData.application_id == '') return this.$Message.error('请选择应用')
        let a = false
        this.appList.forEach( (item) => {
            if(item.id == this.formData.application_id){
                if(item.permissions_id == 998 || item.permissions_id == 999)
                    a = true
            }
        })
        if(a){
            if(this.formData.title == '') return this.$Message.error('请填写'+this.morqtitle+'内容')
        }
        let b = false
        this.appList.forEach( (item) => {
            if(item.id == this.formData.application_id){
                if(item.permissions_id == 997)
                    b = true
            }
        })
        if(b){
            if(this.formData.domain_name == '') return this.$Message.error('请填写独立域名')
        }

        if(this.formData.price == '') return this.$Message.error('请填写价格')
        
        this.$refs[name].validate((valid) => {
            if (valid) {
                if(this.formData.id){
                    //修改订单
                    let data = {
                        price:this.formData.price,
                        id:this.formData.id,
                        application_id:this.formData.application_id,
                        // number:this.selectnumber,  
                        order_no:this.editOrder_no,
                        title:this.formData.title, 
                        domain_name:this.formData.domain_name,     
                        remarks: this.formData.remarks,         
                    }
                    this.loadingb = true
                    update_order(data).then(res => {
                        if (res.code === 200) {
                            this.$Message.success(res.msg)
                            this.getList()
                            this.get_signtemplatelist()
                            this.reset()
                            this.showModel=false
                            this.loadingb = false
                        }
                    }).catch(res => {
                        this.$Message.error(res.msg)
                        this.loadingb = false
                    })
                }else {
                    //新增订单
                    let data = {
                        price:this.formData.price,
                        store_id:this.formData.store_id,
                        application_id:this.formData.application_id,
                        number: this.selectnumber ,
                        title:this.formData.title,//短信内容
                        remarks:this.formData.remarks,
                        domain_name:this.formData.domain_name,//独立域名
                    }
                    this.loadingb = true
                    create_order(data).then(res => {
                        if (res.code === 200) {
                            this.$Message.success(res.msg)
                            this.getList()
                            this.get_signtemplatelist()
                            this.reset()
                            this.showModel=false
                            this.loadingb = false
                        }                        
                    }).catch(res => {
                        this.loadingb = false
                        this.$Message.error(res.msg)
                    })
                }
            } else {
                this.$Message.error('请输入订单信息');
            }
        })
    },
    handleReset (name) {
        this.$refs[name].resetFields();
    },   
    cancel() {
      this.reset()
    },
    showedit(e) {
        console.log('编辑内容',e)
        this.formTitle = 'edit'
        this.editOrder_no = ''
        this.editOrder_no = e.order_no
        this.formData.id = e.id
        this.formData.store_id = e.store_id
        this.formData.application_id = e.application_id
        this.formData.price = e.price
        this.formData.title = e.title
        this.formData.domain_name = e.domain_name
        this.formData.remarks = e.remarks
        this.showDomain = false
        this.showInput = false
        if( e.permissions_id == 997){
            this.showDomain = true
        }
        if( e.permissions_id == 998 || e.permissions_id == 999){
            this.showInput = true
            this.morqtitle = e.permissions_id == 998 ? '模板':'签名'
        }
        this.showModel = true

    },
    goRefund(form) {
        this.refundForm.id = ''
        this.refundForm.oldPrice = ''
        this.refundForm.id = form.id
        this.refundForm.oldPrice = form.price*1
        this.refundForm.price = ''
        this.refundForm.remarks = ''
        this.showRefundModal = true   
    },
    offPayment() {
        this.$Modal.confirm({
            title: '线下支付',
            content: '请问您是否确认该订单线下支付完成！！！',
            onOk: () => {          
                pay_order({id:this.zhongId,pay_type:0}).then(res=>{
                    this.getList()
                    this.get_signtemplatelist()
                    this.$Message.success(res.msg)
                    this.showPay = false
                    
                }).catch(res=>{
                    this.$Message.error(res.msg)
                })
            },
            onCancel: () => {
            }
        });
    },
    examineSuccessful(row,status){      
        if(status == 3)  {
            this.$Modal.confirm({
                title: '审核通过',
                content: '确认该订单审核通过吗？',
                onOk: () => {
                    this.loadinga = true
                    this.failure_reason = ''
                    this.examine_order(row,status)
                    // examine_order({id:row.id}).then(res=>{
                    //     this.loadinga = false
                    //     this.get_signtemplatelist()
                    //     this.$Message.success(res.msg)
                    // }).catch(res=>{
                    //     this.$Message.error(res.msg)
                    //     this.loadinga = false
                    // })
                },
                onCancel: () => {
                    this.loadinga = false
                }
            });
        } else {
            this.zhongzhuanrow = row
            this.showReason = true
        } 
    },
    submitReason(){
        if(this.failure_reason == '') return this.$Message.error("请填写驳回原因")
        this.loadinga = true
        this.examine_order(this.zhongzhuanrow,2)
    },
    closeReason() {
        this.failure_reason = ''
        this.showReason = false
    },
    submitRefund(){
        // if((this.refundForm.price > this.refundForm.oldPrice) || this.refundForm.price == ''||this.refundForm.price == 0){
        //     return this.$Message.error('退款金额不能为空或者为0,且不能大于实际付款金额')
        // }
        this.refundForm.price = this.refundForm.oldPrice
        apply_refund({id:this.refundForm.id,price:this.refundForm.price,remarks:this.refundForm.remarks }).then(res=>{
            this.$Message.success(res.msg)
            this.refundForm.id = ''
            this.refundForm.price = ''
            this.refundForm.remarks = ''
            this.refundForm.oldPrice = ''
            this.getList()
            this.showRefundModal = false          
        }).catch(res=>{
            this.$Message.error(res.msg)
        })
    },
    closeRefund(){
        this.refundForm.id = ''
        this.refundForm.price = ''
        this.refundForm.remarks = ''
        this.refundForm.oldPrice = ''
        this.showRefundModal = false  
    },
    examine_order(row,status){
        examine_order({ts_id:row.ts_id,status:status,failure_reason:this.failure_reason}).then(res=>{
            this.loadinga = false
            this.get_signtemplatelist()
            this.$Message.success(res.msg)
            this.showReason = false
            this.failure_reason = ''
        }).catch(res=>{
            this.$Message.error(res.msg)
            this.loadinga = false
        })
    },
    deldata(id){
      this.$Modal.confirm({
        title: '删除订单！',
        content: '确认删除该订单吗？',
        onOk: () => {
            del_order({id:id}).then(res=>{
                this.getList()
                this.$Message.success(res.msg)
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        },
        onCancel: () => {          
        }
      });
    },
    paySuccessful(e) {
        this.zhongId = e.id
        this.zhongName = e.applicationarr_id.name    
        this.payData.price = e.price
        this.showPay = true
        this.xianshang  = false
        this.xianxia = false
        // this.$Modal.confirm({
        //   title: '购买成功',
        //   content: '确认该订单购买成功吗？',
        //   onOk: () => {
        //   //   noticeDel({id:id}).then(res=>{
        //   //     this.getList()
        //   //     this.$Message.success(res.msg)
        //   //   }).catch(res=>{
        //   //     this.$Message.error(res.msg)
        //   //   })
        //   },
        //   onCancel: () => {            
        //   }
        // });
    },    
    reset(){      
        this.formData.id = ""
        this.formData.application_id = ""
        this.formData.store_id = ""
        this.formData.price = ""
        this.price_group = []
        this.formData.remarks = ''
        this.formData.domain_name = ''
    }
  }
}
</script>
<style>
.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal {
    top: 0;
}
.flex-style {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.form-label {
    padding-right: 12px;
    width: 120px;
    text-align: right;
    font-size: 14px;
    color: #515a6e;
}
.search-list {
    width: 100%;
    display: flex;
    line-height:32px;word-break:keep-all;white-space:nowrap; 
    flex-wrap: wrap;
}
.list-al {
    display: flex;
    margin-right:15px;
    margin-top:5px;
}
/* .time-cla{
  display: flex;
  align-content: center;
  justify-content: center;
} */
.text-zhi {
    width:20px;
    line-height:28px;
    text-align: center;
}
.mod-pay-cont{
    text-align: center;
}
.pay-pay{
    display: flex;
    align-items: center;
    justify-content: center;    
}
.pay-items{
    padding: 50px 0;
    width: 50%;
    text-align: center;    
}
.pay-items img {
    cursor: pointer;
    width: 90px;
    height: 90px;
}
.pay-num {
    font-size: 25px;
    text-align: center;
    font-weight: bold;

}
.pay-ma {       
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 202px;    
    margin: 10px  0;    
}
.mod-pay-succ {
    text-align: center;  
}
.mod-pay-succ  img {
    width: 80px;
    height: 80px;
    margin-top: 50px;
    margin-bottom: 10px;
}
.titlesuc {
    margin-top: 50px;  
    font-size: 25px;
}
.over-list {
    text-align: start;
    margin-left: 190px;
    margin-bottom: 10px;
    /* margin: 10px 190px; */
    color: #0e0d0d;
    font-size: 14px;
}
.over-list span {
    color: #666666;
}
.footer-reason {
    margin-top: 10px;
    text-align: center;
}
.pay-ma img {
    /* width: 200px;
    height: 200px;
    border: 1px solid #4877E8;
    margin: 0px 0px; */
}
.refend-con {
    display:flex;justify-content: flex-start;align-items: center;margin-top: 24px;
}
.refund-tit {
    width: 100px;text-align: end;margin-right: 8px;
}

.search-tit {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;  
}
.search-tit span {
    line-height:32px;word-break:keep-all;white-space:nowrap;    
/* 单行 */
}
.which {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

}
</style>

<style>
.demo-spin-icon-load{
    animation: ani-demo-spin 3s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col{
    top:80px;
    left:80px;
    position: relative;
  /* // border: 1px solid #eee; */
}
</style>

